import dayjs from '@root/plugins/day'

const formatDate = (inputDate: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  const formattedDate: string = new Intl.DateTimeFormat('fr-FR', options).format(inputDate)

  return formattedDate
}

export const formatTimestamp = (timestamp: number|null): string => {
  const date = dayjs(timestamp)

  return date.format('DD/MM/YYYY')
}

export const isAtLeast18 = (timestamp: number): boolean => {
  const now = dayjs()
  const birthDate = dayjs(timestamp)

  // Calculate the difference in years and check if it's at least 18

  return now.diff(birthDate, 'year') >= 18
}

export const isReasonableDate = (timestamp: number): boolean => {
  const now = dayjs()
  const birthDate = dayjs(timestamp)

  return now.diff(birthDate, 'year') < 100
}

export const isPastDate = (timestamp: number): boolean => {
  const now = dayjs()
  const birthDate = dayjs(timestamp)

  return now.isAfter(birthDate)
}

export const isFutureDate = (timestamp: number): boolean => {
  const now = dayjs()
  const birthDate = dayjs(timestamp)

  return now.isBefore(birthDate)
}

export const isLessThanOneYearInFuture = (timestamp: number): boolean => {
  const now = dayjs()
  const birthDate = dayjs(timestamp)

  return now.diff(birthDate, 'year') < 1
}

export {
  formatDate
}

import {
  Alert, AlertType,
} from '@selectra-it/selectra-ui'

import {
  OfferSortStrategy,
} from '@root/settings'
import {
  Offer,
} from '@root/domain/Offer'

import OfferItem from './OfferItem'

export const addMatchRateBannerWarning = (items: Offer[] | undefined, mode: OfferSortStrategy) => {
  if (!items) {
    return []
  }

  const finalItems = items && items.map(item =>
    <OfferItem offer={item} key={item.id} />
  )

  if (mode === OfferSortStrategy.BEST_RATING) {
    const firstNon100MatchRate = items.findIndex(item => item.matchRate !== 100)

    if (firstNon100MatchRate) {
      finalItems.splice(firstNon100MatchRate, 0, <Alert type={AlertType.INFO}>Les offres proposées ont un ou plusieurs niveaux de garantie plus faible que ce que vous avez demandé.</Alert>)
    }
  }

  return finalItems
}

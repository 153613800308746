import {
  FC, useState,
} from 'react'
import {
  Button,
  ButtonSize,
  ButtonType,
  Header as SelectraHeader,
} from '@selectra-it/selectra-ui'
import {
  Link,
} from 'react-router-dom'

import Stepper from '@components/wizard/Stepper'
import SelectraLogoMobile from '@assets/selectra-logo-mobile.svg?react'
import SelectraLogoDesktop from '@assets/selectra-logo-desktop.svg?react'
import Icon from '@components/Icon'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import useIsOfferPage from '@hooks/useIsOfferPage'

import HelpModal from './HelpModal'

const Header: FC = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const isOfferPage = useIsOfferPage()
  const handleModal = () => setIsHelpModalOpen(true)

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP]

  return (
    <SelectraHeader
      leftSlot={(
        <Link to={'/'}>
          <SelectraLogoMobile className="block md:hidden" />
          <SelectraLogoDesktop className="hidden md:block" />
        </Link>
      )}
      rightSlot={(
        <>
          <HelpModal isHelpModalOpen={isHelpModalOpen} setIsHelpModalOpen={setIsHelpModalOpen}/>
          <div>
            <Button variant={ButtonType.SECONDARY} size={ButtonSize.SMALL}
              onClick={handleModal}
              label={readablePhoneNumber(phoneNumber)}
              iconLeft={() => <Icon name='phone' size={16} />}
            />
          </div>
        </>
      )}
      centerSlot={!isOfferPage && (
        <div className='container mx-auto hidden items-start md:flex'>
          <Stepper />
        </div>
      )}
    />
  )
}

export default Header

import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  useState,
} from 'react'

import Icon from '@components/Icon'

const HospitalizationLevelsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Modal open={isModalOpen} setOpen={setIsModalOpen}>
        <div className='inline-flex flex-col justify-start gap-4'>
          <div className="text-[17px] font-semibold leading-snug text-[#343535]">Qu’est-ce que 150%, 200%, 400% ?</div>
          <div className="flex-col items-start justify-start">
            <div className="self-stretch text-base font-semibold text-[#58595b]">Explication :</div>
            <div className="size-4 bg-[#f1983a]/50 opacity-0" />
            <div className="flex flex-col items-start justify-start gap-2 self-stretch">
              <div className="inline-flex items-start justify-start gap-1.5 self-stretch">
                <div className="flex w-5 items-start justify-center self-stretch pt-[11px]">
                  <div className="size-1.5 rounded-full bg-[#6e6f71]" />
                </div>
                <div className="shrink grow basis-0 text-base font-normal text-[#58595b]">Le tarif de base est fixé par la Sécurité sociale (Exemple ici, 30€).</div>
              </div>
              <div className="inline-flex items-start justify-start gap-1.5 self-stretch">
                <div className="flex w-5 items-start justify-center self-stretch pt-[11px]">
                  <div className="size-1.5 rounded-full bg-[#6e6f71]" />
                </div>
                <div className="shrink grow basis-0 text-base font-normal text-[#58595b]">Votre mutuelle rembourse jusqu’au pourcentage indiqué sur ce montant.</div>
              </div>
            </div>
            <div className="size-6 bg-[#f1803a]/50 opacity-0" />
            <div className="self-stretch text-base font-semibold text-[#58595b]">Exemple avec un tarif de base de 30€ :</div>
            <div className="size-4 bg-[#f1983a]/50 opacity-0" />
            <div className="flex flex-col items-start justify-start self-stretch">
              <div className="inline-flex items-start justify-start gap-px self-stretch rounded-t-lg border border-[#d6d7d8] bg-[#d6d7d8]">
                <div className="inline-flex w-[115px] flex-col items-start justify-center gap-3 rounded-tl-lg p-2">
                  <div className="self-stretch text-base font-semibold text-[#343535]">%</div>
                </div>
                <div className="inline-flex flex-col items-start justify-center gap-3 rounded-tr-lg p-2">
                  <div className="text-base font-semibold text-[#343535]">Montant remboursé</div>
                </div>
              </div>
              <div className="inline-flex items-start justify-start gap-px self-stretch border border-[#d6d7d8]">
                <div className="inline-flex w-[115px] flex-col items-start justify-start gap-3 self-stretch px-2 py-3">
                  <div className="self-stretch text-base font-semibold text-[#58595b]">100%</div>
                </div>
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3 self-stretch border-l border-[#d6d7d8] px-2 py-3">
                  <div className="self-stretch text-base font-normal text-[#58595b]">30€</div>
                </div>
              </div>
              <div className="inline-flex items-start justify-start gap-px self-stretch border border-[#d6d7d8]">
                <div className="inline-flex w-[115px] flex-col items-start justify-start gap-3 self-stretch px-2 py-3">
                  <div className="self-stretch text-base font-semibold text-[#58595b]">150%</div>
                </div>
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3 self-stretch border-l border-[#d6d7d8] px-2 py-3">
                  <div className="self-stretch text-base font-normal text-[#58595b]">45€</div>
                </div>
              </div>
              <div className="inline-flex items-start justify-start gap-px self-stretch border border-[#d6d7d8]">
                <div className="inline-flex w-[115px] flex-col items-start justify-start gap-3 self-stretch px-2 py-3">
                  <div className="self-stretch text-base font-semibold text-[#58595b]">200%</div>
                </div>
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3 self-stretch border-l border-[#d6d7d8] px-2 py-3">
                  <div className="self-stretch text-base font-normal text-[#58595b]">60€</div>
                </div>
              </div>
              <div className="inline-flex items-start justify-start gap-px self-stretch rounded-b-lg border border-[#d6d7d8]">
                <div className="inline-flex w-[115px] flex-col items-start justify-start gap-3 self-stretch px-2 py-3">
                  <div className="self-stretch text-base font-semibold text-[#58595b]">400%</div>
                </div>
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3 self-stretch border-l border-[#d6d7d8] px-2 py-3">
                  <div className="self-stretch text-base font-normal text-[#58595b]">120€</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <Icon name='home-health' width={20}
          height={20} className='mb-1 inline-block fill-[#808284]'
        />: Prise en charge des dépassements d&apos;honoraires et de la chambre particulière. <br/>
        <span className="cursor-pointer text-primary-400 underline " onClick={() => {
          setIsModalOpen(true)
        }}
        >Qu’est-ce que 150%, 200%... ?</span>
      </div>
    </>
  )
}

export default HospitalizationLevelsModal

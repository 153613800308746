export default {
  wizard: {
    steps: {
      insuranceGender: {
        items: {
          men: 'Un homme',
          women: 'Une femme',
        },
      },
      birthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/1980',
      },
      profession: {
        label: 'Autres professions',
      },
      socialRegime: {
        label: 'Régime social',
        description: '88% des Français sont couverts par le régime général de la Sécurité Sociale.',
      },
      children: {
        label: 'Nombre d\'enfants',
      },
      childrenBirthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/2024',
      },
      postalCode: {
        label: 'Code postal',
        description: 'Ex : 75000 Paris',
      },
      insuranceDate: {
        label: 'Autre date',
        description: 'Ex : 31/01/2024',
        today: 'Aujourd\'hui',
        tomorrow: 'Demain',
      },
      name: {
        firstName: {
          label: 'Prénom',
        },
        lastName: {
          label: 'Nom',
        },
      },
      email: {
        label: 'Adresse e-mail',
        nextButton: 'Voir les offres',
        checkbox: {
          accept_partners: 'J\'accepte qu’un conseiller Selectra m’aide dans la comparaison de contrat de mutuelle santé.',
        },
      },
      regularHealthLevel: {
        description: 'Soins courants : visites chez le généraliste, radiographies, médicaments et analyses.',
      },
      hospitalLevel: {
        description: 'Hospitalisation : frais de séjour, actes des médecins et chambres particulières en hôpital.',
      },
      opticalLevel: {
        description: 'Optique : une monture avec verres complexes et lentilles.',
      },
      dentalLevel: {
        description: 'Dentaire : l’orthodontie, des soins et prothèses dentaires.',
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        description: 'Ex : 06 86 52 04 12',
      },
      next: 'Suivant',
      coverageLevel: {
        MIN: {
          regularHealthLevel: 'Jusqu’à <strong>30€</strong>',
          hospitalLevel: '<strong>Sans indemnisation</strong>',
          opticalLevel: '<strong>Sans</strong> prise en charge',
          dentalLevel: '<strong>Contrôles réguliers</strong>',
          label: 'Minimum',
        },
        LOW: {
          regularHealthLevel: 'Jusqu’à <strong>44€</strong>',
          hospitalLevel: 'Jusqu\'à <strong>150%</strong> + chambre privée <strong>40€</strong>',
          opticalLevel: 'Jusqu\'à <strong>200€</strong>',
          dentalLevel: 'Jusqu\'à <strong>150€</strong>',
          label: 'Faible',
        },
        MEDIUM: {
          regularHealthLevel: 'Jusqu’à <strong>67€</strong>',
          hospitalLevel: 'Jusqu\'à <strong>200%</strong> + chambre privée <strong>60€</strong>',
          opticalLevel: 'Jusqu\'à <strong>250€</strong>',
          dentalLevel: 'Jusqu\'à <strong>250€</strong>',
          label: 'Moyen',
        },
        HIGH: {
          regularHealthLevel: 'Jusqu’à <strong>90€</strong>',
          hospitalLevel: 'Jusqu\'à <strong>400%</strong> + chambre privée <strong>100€</strong>',
          opticalLevel: 'Plus de <strong>300€ + chirurgie réfractive</strong>',
          dentalLevel: 'Jusqu\'à <strong>400€ + forfait implant</strong>',
          label: 'Fort',
        },
      },
    },
  },
}

import {
  InputGroup, getErrorMessages, ErrorMessages, DateInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  useDispatch,
} from 'react-redux'
import {
  Controller,
  SubmitHandler,
} from 'react-hook-form'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import {
  isAtLeast18,
  isPastDate,
  isReasonableDate,
} from '@root/util/date'

import useAdultStepHandlers from './useAdultStepHandlers'

const BirthDate = () => {
  const dispatch = useDispatch()
  const {
    form: {
      handleSubmit,
      formState: {
        errors,
      },
      control,
      watch,
    },

  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    id, backHandler, nextHandler,
  } = useAdultStepHandlers(wizardRoutes.POSTAL_CODE, wizardRoutes.SOCIAL_REGIME, watch('adults').length)

  const errorType = errors.adults?.[id]?.birthDate?.type

  const status = useGetInputStatus(errorType)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.birthDate.required'),
    atLeast18: t('validation:validation.form.birthDate.atLeast18'),
    reasonableDate: t('validation:validation.form.birthDate.reasonableDate'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup description={t('wizard:wizard.steps.birthDate.description')}
          errors={inputErrors}
        >
          <Controller
            name={`adults.${id}.birthDate` as 'adults.0.birthDate'}
            control={control}
            rules={{
              validate: {
                required: value => {
                  return !!value
                },
                reasonableDate: value => {
                  if (!value) {
                    return true
                  }

                  return !!(isReasonableDate(value) && isPastDate(value))
                },
                atLeast18: value => {
                  if (!value) {
                    return true
                  }

                  return !!isAtLeast18(value)
                },
              },
            }}
            render={renderParams => {
              const {
                field: {
                  value,
                  ref,
                  name,
                  onChange,
                },
              } = renderParams

              return (
                <DateInput label={t('wizard:wizard.steps.birthDate.label')}
                  value={value ? new Date(value) : null}
                  name={name}
                  status={status}
                  onChange={date => {
                    onChange(date?.getTime())
                  }}
                  ref={ref}
                />
              )
            }}
          />

        </InputGroup>
      </div>

      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default BirthDate

import {
  http,
} from 'msw'

import user from '@fixtures/user.json'
import offers from '@fixtures/offers.json'
import socialRegime from '@fixtures/socialRegime.json'
import profession from '@fixtures/profession.json'
import level from '@fixtures/level.json'
import insurers from '@fixtures/insurers.json'

const handlers = [
  http.get('*/api/health/professions', (req, res, ctx) => {
    return res(ctx.json(profession))
  }),
  http.get('*/api/health/social-regimes', (req, res, ctx) => {
    return res(ctx.json(socialRegime))
  }),
  http.get('*/api/health/coverage-levels', (req, res, ctx) => {
    return res(ctx.json(level))
  }),
  http.get('*/api/health/estimation/*', (req, res, ctx) => {
    return res(ctx.json(offers))
  }),
  http.get('*/api/user/*', (req, res, ctx) => {
    return res(ctx.json(user))
  }),
  http.get('*/api/insurers', (req, res, ctx) => {
    return res(ctx.json(insurers))
  })
]

export {
  handlers
}

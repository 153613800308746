import {
  Profession,
} from '@root/services/profession'
import {
  SocialRegime,
} from '@root/services/socialRegime'

import {
  Gender,
} from './Gender'
import {
  Nullable,
} from './Wizard'

export enum TYPE {
  PRINCIPAL = 'PRINCIPAL',
  SPOUSE = 'SPOUSE'
}

export type AdultInformation = {
  birthDate: Nullable<number>,
  gender: Nullable<Gender>,
  profession: Nullable<Profession>,
  professionRadio: Nullable<string>,
  socialRegime: Nullable<SocialRegime>,
  type: TYPE,
}

import {
  matchPath,
  NavigateOptions,
} from 'react-router-dom'
import {
  ReactElement,
} from 'react'

import {
  StepperGroup,
  steps,
} from '@root/routes/wizard'

import {
  Beneficiary,
} from './Beneficiary'
import {
  AdultInformation,
} from './Adult'
import {
  Address,
} from './Address'

export type Nullable<T> = T | null

export type Level = 'min' | 'low'| 'medium'| 'max' | ''

export type Children = {
  birthDate: Nullable<number>
}

export type PhoneNumber = {
  number: string
  countryCode: string
}

export type WizardFormData = {
  insuranceDate: Nullable<number>,
  insuranceDateRadio: string,
  regularHealthLevel: Level,
  opticalLevel: Level,
  hospitalLevel: Level,
  dentalLevel: Level,
  firstName: string,
  lastName: string,
  email: string,
  amountOfChildren: string,
  adults: AdultInformation[],
  children: Children[],
  beneficiary: Beneficiary,
  address: Nullable<Address>
  phoneNumber: PhoneNumber
  terms_and_conditions: boolean;
  accept_partners: boolean;
};

export type NavigateNext = (data: WizardFormData, options: NavigateOptions | undefined) => void

export interface Step {
  name: string;
  path: string;
  element: () => ReactElement,
  shouldRender: (state: WizardFormData) => boolean;
  group?: StepperGroup,
  children?: Step[],
}

type RouteInformation = {
  previous: Step | undefined,
  next: Step | undefined,
  index: number,
}

export const getRouteInformation = (currentRoute: string, state: WizardFormData): RouteInformation => {
  const currentIndex = steps.findIndex(step => matchPath(step.path, currentRoute))

  const stepsAfterCurrent = steps.slice(currentIndex + 1, steps.length)

  const next = stepsAfterCurrent.find(step => step.shouldRender(state))
  const previous = findPreviousStep(currentRoute, state)

  return {
    index: currentIndex,
    next,
    previous,
  }
}

const findPreviousStep = (currentRoute: string, state: WizardFormData): Step | undefined => {
  const currentIndex = steps.findIndex(step => matchPath(step.path, currentRoute))
  if (currentIndex <= 0) {
    return undefined // Route not found in steps
  }

  const stepsBeforeCurrentStep = steps.slice(0, currentIndex).reverse()

  return stepsBeforeCurrentStep.find(step => step.shouldRender(state))
}

export const findStepIndex = (currentRoute: string): number => {
  return steps.findIndex(step => step.path === currentRoute)
}

export const findCurrentStep = (currentRoute: string): Step | undefined => {
  return steps.find(step => step.path === currentRoute)
}

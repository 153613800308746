import {
  InputGroup, getErrorMessages, ErrorMessages, GoogleAutocomplete,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  generatePath,
  useNavigate,
} from 'react-router-dom'
import {
  Controller,
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'

import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import {
  numberOfAdults,
} from '@root/domain/Beneficiary'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  WizardFormData,
} from '@root/domain/Wizard'

const PostalCode: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    form: {
      watch,
      handleSubmit,
      formState: {
        errors,
      },
      control,
    },
  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPostalCode'),
  }, errors?.address?.type)

  const beneficiary = watch('beneficiary')
  const amountOfAdults = numberOfAdults[beneficiary]

  const backHandler = () => {
    const path = generatePath(wizardRoutes.BIRTH_DATE, {
      id: (amountOfAdults - 1).toString(),
    })
    navigate(`/${path}`)
  }

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField({
      ...data,
    }))

    const nextRoute = watch('children').length > 0 ? wizardRoutes.CHILDREN : wizardRoutes.EMAIL
    navigate(`/${generatePath(nextRoute)}`)
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputGroup
        description={t('wizard:wizard.steps.postalCode.description')}
        errors={inputErrors}
      >
        <Controller name='address' control={control}
          rules={{
            required: true,
          }} render={renderParams => {
            const {
              field: {
                value,
                ref,
                name,
                onChange,
              },
            } = renderParams

            return (
              <GoogleAutocomplete
                id='address'
                value={value}
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                componentRestrictions={{
                  country: 'FR',
                }}
                label={t('wizard:wizard.steps.postalCode.label')}
                types={[
                  'postal_code'
                ]}
                ref={ref}
                name={name}
                onChange={onChange}
                type={'tel'}
              />
            )
          }}
        />

      </InputGroup>

      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default PostalCode

import {
  InputGroup, getErrorMessages, ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
  DateInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
  useEffect,
  useRef,
} from 'react'
import {
  generatePath,
  useNavigate,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'
import {
  Controller,
  SubmitHandler,
} from 'react-hook-form'

import dayjs from '@root/plugins/day'
import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import {
  isFutureDate,
  isReasonableDate,
} from '@root/util/date'

const InsuranceDate: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    form: {
      register, formState: {
        errors,
      },
      setValue,
      watch,
      trigger,
      control,
      handleSubmit,
    },
    onBackHandler,
  } = useEstimationWizardForm({
    revalidateMode: 'onChange',
  })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const status = useGetInputStatus(errors?.insuranceDate?.type)
  const contratDateRadioStatus = useGetRadioStatus(errors?.insuranceDateRadio?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.insuranceDate.required'),
    reasonableDate: t('validation:validation.form.insuranceDate.reasonableDate'),
  }, errors?.insuranceDate?.type)

  const now = dayjs() // today's date
  const tomorrow = now.add(1, 'day') // tomorrow's date
  const todayValue = now.format('DD/MM/YYYY') // formatted as DD/MM/YYYY
  const tomorrowValue = tomorrow.format('DD/MM/YYYY')

  const insuranceDate = watch('insuranceDate')
  const insuranceDateRadio = watch('insuranceDateRadio')

  const items = [{
    id: 'today', label: t('wizard:wizard.steps.insuranceDate.today'), value: todayValue,
  }, {
    id: 'later', label: t('wizard:wizard.steps.insuranceDate.tomorrow'), value: tomorrowValue,
  }]

  useEffect(() => {
    if (insuranceDate !== null) {
      setValue('insuranceDateRadio', '')
      trigger('insuranceDateRadio')
    }
  }, [insuranceDate, setValue, trigger])

  useEffect(() => {
    if (insuranceDateRadio?.length > 0) {
      setValue('insuranceDate', null)
      trigger('insuranceDate')
    }
  }, [insuranceDateRadio, setValue, trigger])

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField({
      ...data,
    }))

    const path = generatePath(wizardRoutes.INSURANCE_GENDER, {
      id: '0',
    })
    navigate(`/${path}`)
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
        description={t('wizard:wizard.steps.insuranceDate.description')}
      >
        <RadioStackedContainer>
          {items.map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
              }}
              status={contratDateRadioStatus}
              {...register('insuranceDateRadio', {
                validate: {
                  required: (value, {
                    insuranceDate,
                  }) => {
                    return value?.length > 0 || insuranceDate !== null
                  },
                },
              })}
              dataCy={value}
            />
          ))}
        </RadioStackedContainer>

        <div className='py-2 text-center text-neutral-400'>
          ou
        </div>

        <Controller
          name='insuranceDate'
          control={control}
          rules={{
            validate: {
              required: (value, {
                insuranceDateRadio,
              }) => {
                return value !== null || insuranceDateRadio?.length > 0
              },
              reasonableDate: value => {
                if (!value) {
                  return true
                }

                return !!(isReasonableDate(value) && isFutureDate(value))
              },
            },
          }}
          render={renderParams => {
            const {
              field: {
                value,
                ref,
                name,
                onChange,
              },
            } = renderParams

            return (
              <DateInput
                label={t('wizard:wizard.steps.insuranceDate.label')}
                value={value ? new Date(value) : null}
                name={name}
                status={status}
                onChange={date => {
                  onChange(date?.getTime())
                }}
                ref={ref}
              />
            )
          }}
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default InsuranceDate

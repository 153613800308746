import {
  Estimation,
} from '@root/domain/Estimation'
import {
  Offer,
} from '@root/domain/Offer'
import {
  OfferSortStrategy,
  settings,
} from '@root/settings'
import {
  apiService,
} from '@services/apiService'

export const BEST_PRICE = 'best_price'
export const BEST_RATING = 'BEST_RATING'
export const FREE_TRIAL_1_MONTH = 'FREE_TRIAL_1_MONTH'

export interface Tags {
  [BEST_PRICE]: string,
  [BEST_RATING]: string,
  [FREE_TRIAL_1_MONTH]: string | string[]
}

interface EstimationWithOffers {
  items: Offer[],
  estimation: Estimation,
  tags: Tags,
}

interface GetOffersQuery {
  estimationId: string,
  field?: string,
  direction?: string,
}

export enum MerStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'error',
  DONE = 'done',
}

interface FetchMerResponse {
  status: MerStatus
  description: string
  type: string
  url: string
}

interface FetchMerQuery {
  offerId: string,
  merId: string,
}

const OfferSortQuery: Record<OfferSortStrategy, string> = {
  [OfferSortStrategy.BEST_PRICE]: 'sort[0][direction]=asc&sort[0][field]=monthly_price',
  [OfferSortStrategy.BEST_RATING]: 'sort[0][direction]=desc&sort[0][field]=match_rate&sort[1][direction]=asc&sort[1][field]=monthly_price',
}

export const OffersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ['Offers'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getOffersByEstimationId: builder.query<EstimationWithOffers, GetOffersQuery>({
        query: ({
          estimationId,
        }) => {
          const query = OfferSortQuery[settings.offerSortStategy]
          return `api/health/estimation/${estimationId}?${query}`
        },
        transformResponse: (response: EstimationWithOffers) => response,
        providesTags: result => {
          const tags = result
            ? [
              ...result.items.map(({
                id,
              }) => ({
                type: 'Offers' as const, id,
              })),
              {
                type: 'Offers' as const, id: 'LIST',
              }
            ]
            : [{
              type: 'Offers' as const, id: 'LIST',
            }]

          return tags
        },
      }),
      createEstimation: builder.mutation({
        query: ({
          uuid, ...data
        }) => ({
          url: `api/health/estimation/${uuid}`,
          method: 'POST',
          body: {
            ...data,
            _method: 'PUT',
          },
        }),
      }),
      createOfferMer: builder.mutation({
        query: ({
          offerId, id, ...data
        }) => ({
          url: `api/health/offers/${offerId}/mer/${id}`,
          method: 'POST',
          body: {
            ...data,
          },
        }),
      }),
      fetchMer: builder.query<FetchMerResponse, FetchMerQuery>({
        query: ({
          offerId, merId,
        }) => ({
          url: `api/health/offers/${offerId}/mer/${merId}`,
          method: 'GET',
        }),
      }),
    }),
  })

export const {
  useGetOffersByEstimationIdQuery,
  useCreateEstimationMutation,
  useCreateOfferMerMutation,
  useFetchMerQuery,
} = OffersApi

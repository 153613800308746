import {
  FC, useEffect, useState,
} from 'react'
import {
  v4 as uuidv4,
} from 'uuid'
import {
  InputGroup, TextInput, getErrorMessages, ErrorMessages, CheckboxClassic, ButtonType, ButtonSize, Button,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  generatePath,
  useNavigate,
} from 'react-router-dom'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'

import EmailValidator from '@root/util/validation/EmailValidator'
import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import Arrow from '@assets/icons/arrow-backward.svg?react'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  useCreateEstimationMutation,
} from '@root/services/offers'
import {
  useCreateUserMutation,
} from '@root/services/user'
import {
  setFormField,
} from '@root/services/formSlice'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import {
  beneficiaryHasChildren,
} from '@root/domain/Beneficiary'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import useMediaQuery from '@hooks/useMediaQuery'
import {
  formatTimestamp,
} from '@root/util/date'

const Email: FC = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const {
    form: {
      register,
      handleSubmit,
      formState: {
        errors,
      },
      setFocus,
      watch,
    },
  } = useEstimationWizardForm({
    mode: 'onSubmit',
  })
  const [loading, setIsLoading] = useState(false)
  const [createEstimation] = useCreateEstimationMutation()
  const [createUser] = useCreateUserMutation()
  const dispatch = useDispatch()

  const emailInputStatus = useGetInputStatus(errors.email?.type)
  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addEmail'),
    email: 'Adresse e-mail invalide',
  }, errors.email?.type)

  const beneficiary = watch('beneficiary')
  const children = watch('amountOfChildren')
  const amountOfChildren = parseInt(children, 10)

  const backHandler = () => {
    if (beneficiaryHasChildren[beneficiary]) {
      const path = generatePath(wizardRoutes.CHILDREN_BIRTH_DATE, {
        id: (amountOfChildren - 1).toString(),
      })
      return navigate(`/${path}`)
    }

    const path = generatePath(wizardRoutes.POSTAL_CODE)
    navigate(`/${path}`)
  }

  const onSubmit: SubmitHandler<WizardFormData> = async data => {
    dispatch(setFormField(data))
    const userId = uuidv4()
    const estimationId = uuidv4()

    setIsLoading(true)

    try {
      await createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phoneNumber.number, // TODO
        country_code: 'FR', // TODO
        uuid: userId,
        birthDate: formatTimestamp(data.adults[0].birthDate),
      }).unwrap()

      const adults = data.adults.map(adult => ({
        birthDate: formatTimestamp(adult.birthDate),
        gender: adult.gender,
        professionId: adult.profession?.id ?? adult.professionRadio,
        socialRegimeId: adult.socialRegime?.id,
        type: adult.type,
      }))

      const children = data.children.map(child => ({
        birthDate: formatTimestamp(child.birthDate),
      }))

      await createEstimation({
        consultation: data.regularHealthLevel,
        hospitalization: data.hospitalLevel,
        optic: data.opticalLevel,
        dental: data.dentalLevel,
        address: data.address,
        contractStartDate: data.insuranceDate !== null ? formatTimestamp(data.insuranceDate) : data.insuranceDateRadio,
        userId,
        uuid: estimationId,
        adults,
        children,
        acceptPartners: data.accept_partners ?? false,
      }).unwrap()

      navigate(`/offre/${estimationId}?preload=true`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='flex flex-col'>
      <form
        className='flex w-full grow flex-col justify-between space-y-6'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <InputGroup errors={inputErrors}>
            <TextInput
              label={t('wizard:wizard.steps.email.label')}
              {...register('email', {
                required: true,
                validate: {
                  email: EmailValidator,
                },
              })}
              status={emailInputStatus}
              dataCy='email'
              ref={e => {
                register('email').ref(e)
                e?.focus() // Assign ref to the first input
              }}
              autoComplete='email'
            />
          </InputGroup>

          <InputGroup
            errors={
              getErrorMessages({
                ...ErrorMessages,
                required: t('validation:validation.form.addPhoneNumber'),
                phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
              }, errors.phoneNumber?.number?.type)}
            description={t('wizard:wizard.steps.phoneNumber.description')}
          >
            <PhoneNumberInput<WizardFormData>
              label={t('wizard:wizard.steps.phoneNumber.label')}
              register={register}
              name='phoneNumber.number'
              status={phoneNumberInputStatus}
            />
          </InputGroup>

          <div className='md:col-span-2'>
            <CheckboxClassic
              {...register('terms_and_conditions', {
                required: true,
              })}
              id="terms_and_conditions"
              label="*J’accepte que mes données soient utilisées afin que les partenaires de Selectra me contactent si je demande à être mis en relation."
              error={errors.terms_and_conditions}
            />
          </div>

          <div className='md:col-span-2'>
            <CheckboxClassic
              {...register('accept_partners', {
                value: false,
              })}
              id="accept_partners"
              label={t('wizard:wizard.steps.email.checkbox.accept_partners')}
              error={errors.accept_partners}
            />
          </div>
        </div>
        <div className='flex gap-5'>
          <div>
            <Button
              type='button'
              variant={ButtonType.LIGHT}
              size={ButtonSize.LARGE}
              pill
              rounded={!isDesktop}
              label={isDesktop ? 'Retour' : undefined}
              iconLeft={Arrow}
              onClick={backHandler}
            />
          </div>
          <Button
            type='submit'
            disabled={loading}
            label={t('wizard:wizard.steps.email.nextButton')}
            variant={ButtonType.SUCCESS}
            size={ButtonSize.LARGE}
            pill
            dataCy={'submit'}
          />
        </div>
      </form>

      <p className='mt-16 text-xs text-neutral-400'>
          ** La société Selectra SAS traite les données personnelles recueillies dans ce questionnaire pour proposer des offres correspondant au profil de l’internaute, réaliser des opérations d’analyse et de statistiques, pour assurer la relation commerciale avec les clients. Vous disposez de différents droits (notamment le droit d’opposition, d’accès, de rectification, d’effacement). Pour en savoir plus, consultez notre {' '}
        <a className='text-primary-400 underline' href="https://selectra.info/la-page-a-jojo-le-dpo"
          target="_blank" rel="noopener noreferrer"
        >Charte de protection des données personnelles.</a>
      </p>
    </div>
  )
}

export default Email

import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  CoverageLevel,
} from '@root/services/level'

interface CoverageLevelIndicatorProps {
  level: CoverageLevel
}

const GreenBarMap: Record<CoverageLevel, number> = {
  MIN: 1,
  LOW: 2,
  MEDIUM: 3,
  HIGH: 4,
}

const CoverageLevelIndicator: FC<CoverageLevelIndicatorProps> = ({
  level,
}) => {
  const {
    t,
  } = useTranslation(['wizard'])
  const greenBarLength = GreenBarMap[level]
  const grayBarLength = 4 - greenBarLength

  const greenBar = Array.from({
    length: greenBarLength,
  }).map((_, index) => (
    <div
      key={index}
      className="h-5 w-[7px] rounded-sm bg-success-light"
    />
  ))

  const grayBar = Array.from({
    length: grayBarLength,
  }).map((_, index) => (
    <div
      key={index}
      className='h-5 w-[7px] rounded-sm bg-neutral-100'
    />
  ))

  return (
    <div className='inline-flex h-[52px] shrink-0 basis-[86px] flex-col items-center justify-center gap-2 border-r border-[#a9abae] pl-2 pr-4'>
      <div className='inline-flex items-center justify-center gap-[3px]'>
        {greenBar}
        {grayBar}
      </div>
      <span className='text-sm font-semibold leading-160 text-neutral-400'>{t(`wizard.steps.coverageLevel.${level}.label` as 'wizard.steps.coverageLevel.HIGH.label')}</span>
    </div>
  )
}

export default CoverageLevelIndicator

import {
  ErrorMessages,
  InputGroup,
  RadioBox,
  RadioBoxContainer,
  getErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useEffect,
  useRef,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'
import {
  useDispatch,
} from 'react-redux'
import {
  SubmitHandler,
} from 'react-hook-form'

import NavigationButtons from '@components/form/NavigationButtons'
import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  Beneficiary,
  beneficiaryHasChildren,
  BeneficiaryIcons,
} from '@root/domain/Beneficiary'
import {
  TYPE,
} from '@root/domain/Adult'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'

const items = [
  {
    id: Beneficiary.PRINCIPAL,
    label: 'Un adulte',
    value: Beneficiary.PRINCIPAL,
  },
  {
    id: Beneficiary.PRINCIPAL_AND_CHILDREN,
    label: 'Un adulte + enfant(s)',
    value: Beneficiary.PRINCIPAL_AND_CHILDREN,
  },
  {
    id: Beneficiary.PRINCIPAL_AND_SPOUSE,
    label: 'Un couple',
    value: Beneficiary.PRINCIPAL_AND_SPOUSE,
  },
  {
    id: Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN,
    label: 'Un couple + enfant(s)',
    value: Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN,
  }
]

const defaultFormState: Record<Beneficiary, Partial<Pick<WizardFormData, 'adults' | 'children' | 'amountOfChildren'>>> = {
  [Beneficiary.PRINCIPAL]: {
    adults: [
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.PRINCIPAL,
      }
    ],
    children: [],
    amountOfChildren: '0',
  },
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: {
    adults: [
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.PRINCIPAL,
      }
    ],
    children: [
      {
        birthDate: null,
      }
    ],
    amountOfChildren: '0',
  },
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: {
    adults: [
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.PRINCIPAL,
      },
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.SPOUSE,
      }
    ],
    children: [
      {
        birthDate: null,
      }
    ],
    amountOfChildren: '0',
  },
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: {
    adults: [
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.PRINCIPAL,
      },
      {
        birthDate: null,
        gender: null,
        profession: null,
        professionRadio: null,
        socialRegime: {
          id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
          key: 'general',
          label: 'Général',
        },
        type: TYPE.SPOUSE,
      }
    ],
    children: [],
    amountOfChildren: '0',
  },
}

const BeneficiaryComponent: FC = () => {
  const dispatch = useDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const {
    form: {
      register,
      handleSubmit,
      formState: {
        errors,
        isDirty,
      },
      watch,
    },
    next,
    onBackHandler,
  } = useEstimationWizardForm({})

  const BeneficiaryStatus = useGetRadioStatus(errors.beneficiary?.type)

  const inputErrors = getErrorMessages(
    {
      ...ErrorMessages,
      required: t('validation:validation.form.mustSelectBeneficiary'),
    },
    errors.beneficiary?.type
  )

  const beneficiary = watch('beneficiary')

  useEffect(() => {
    if (!beneficiaryHasChildren[beneficiary]) {
      dispatch(setFormField({
        amountOfChildren: '0',
      }))
    }
  }, [beneficiary, dispatch])

  useSubmitFormOnChange(isDirty, beneficiary, formRef)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField({
      ...data,
      ...isDirty ? defaultFormState[data.beneficiary] : [],
    }))

    next(data, {})
  }

  return (
    <form
      ref={formRef}
      className="flex w-full grow flex-col justify-between space-y-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-6">
        <InputGroup errors={inputErrors}>
          <RadioBoxContainer>
            {items.map(({
              id,
              label,
              value,
            }) => (
              <RadioBox
                key={id}
                id={id}
                item={{
                  label,
                  value,
                  icon: BeneficiaryIcons[id],
                }}
                status={BeneficiaryStatus}
                {...register('beneficiary', {
                  required: true,
                })}
                dataCy={value}
              />
            ))}
          </RadioBoxContainer>
        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default BeneficiaryComponent

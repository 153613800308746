import {
  datadogRum,
} from '@datadog/browser-rum'

if (import.meta.env.VITE_ENV === 'production') {
  datadogRum.init({
    applicationId: '3c6057d0-627d-45eb-941f-7503e8f442e4',
    clientToken: 'pubfebb7fdf0e04f77cc5acc31965166d8b',
    site: 'datadoghq.com',
    service: 'health-insurance',
    env: import.meta.env.VITE_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
